<!-- <template>
  <v-card-text class="d-flex flex-column">
    <form @submit.prevent="sendMsg" class="d-flex flex-row align-center">
      <v-text-field
        class="mr-2"
        v-model="state.msgText"
        hide-details=""
        outlined
        dense
        placeholder="Type"
      ></v-text-field>
      <v-btn :color="canSend ? 'primary' : ''" @click="sendMsg" :loading="state.isSending" icon>
        <v-icon>{{ icons.mdiSend }}</v-icon>
      </v-btn>
    </form>

    <div class="d-flex flex-column mt-3">
      <div class="d-flex flex-row flex-wrap align-center audio-recorder">
        <v-btn v-show="!state.previewUrl" @click="toggleRecording" small class="mr-2">
          <v-icon :color="state.isRecording ? '#F60000' : ''">
            {{ state.isRecording ? icons.mdiStop : icons.mdiMicrophone }}
          </v-icon>
          {{ state.isRecording ? "Stop recording" : "Record a voice note" }}
        </v-btn>

        <audio v-show="state.previewUrl" ref="recordingPreview" controls src=""></audio>

        <v-btn v-show="state.previewUrl" class="ml-2" small @click="clearRecording">
          <v-icon>{{ icons.mdiDelete }}</v-icon>
          Clear Note
        </v-btn>
      </div>

      <div class="mt-4">
        <v-btn small class="mr-2" v-show="!state.files.length" @click="openFilePicker">
          <v-icon> {{ icons.mdiFile }} </v-icon>
          Add attachments
        </v-btn>

        <v-file-input
          v-model="state.files"
          :rules="attachmentsValidation"
          ref="filePicker"
          dense
          outlined
          label="Add attachments to message"
          :class="state.files.length ? 'd-flex' : 'd-none'"
          clearable
          small-chips
          show-size=""
          multiple
        >
        </v-file-input>
      </div>
    </div>
  </v-card-text>
</template>

<script>
import axios from "@axios";
import { validateFileSizes } from "@/helpers";
import { mdiSend, mdiMicrophone, mdiStop, mdiDelete, mdiFile } from "@mdi/js";
import { computed, ref, reactive, onUnmounted } from "@vue/composition-api";
import { useMessages } from "@/composables/chat/messages";

export default {
  name: "SendMessage",
  setup() {
    const { activeConversation } = useMessages();
    const state = reactive({
      sizeError: false,
      msgText: "",
      previewUrl: "",
      isSending: false,
      isRecording: false,
      finalBlob: null,
      files: [],
    });

    const recordingPreview = ref(null);
    const filePicker = ref(null);

    const uploadSizeLimit = 50;
    const attachmentsValidation = [(files) => validateFileSizes(files, uploadSizeLimit)];

    function openFilePicker() {
      const pickerElem = filePicker.value.$el;
      const button = pickerElem.querySelector("button");

      button.click();
    }

    const canSend = computed(() => {
      if (state.isRecording) return false;

      //const filesAboveLimit = state.files.some((file) => file.size > uploadSizeLimit * 1000 * 1000);
      //if (filesAboveLimit) return false;

      if (state.msgText || state.files.length || state.finalBlob) return true;

      return false;
    });

    var audioStream = null,
      mediaRecorder = null,
      audioChunks = [];

    async function startRecording() {
      try {
        audioChunks = [];
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            sampleRate: 44100,
          },
        });

        mediaRecorder = new MediaRecorder(audioStream);

        mediaRecorder.ondataavailable = function (event) {
          audioChunks.push(event.data);
        };

        mediaRecorder.onstop = handleStop;
        mediaRecorder.start();
        state.isRecording = true;
      } catch (err) {
        console.log(err);
      }
    }

    function stopRecording() {
      mediaRecorder.stop();
    }

    function handleStop() {
      audioStream.getTracks().forEach(function (track) {
        track.stop();
      });
      state.finalBlob = new Blob(audioChunks, { type: "audio/webm" });
      state.previewUrl = URL.createObjectURL(state.finalBlob);
      recordingPreview.value.src = state.previewUrl;
    }

    function toggleRecording() {
      if (state.isRecording) stopRecording();
      else startRecording();
      state.isRecording = !state.isRecording;
    }

    function clearRecording() {
      state.previewUrl = "";
      audioChunks = [];
      state.finalBlob = null;
    }

    const hideFileDetails = computed(() => {
      if (!state.files.length) return true;
      return false;
    });

    async function sendMsg() {
      try {
        if (!canSend.value) return;
        state.isSending = true;

        const form = new FormData();
        form.append("conversationId", activeConversation.value.id);
        form.append("message", state.msgText);
        for (var file of state.files) form.append("attachments", file);
        if (state.finalBlob) form.append("attachments", state.finalBlob, `${Date.now()}.mp3`);

        const response = await axios.post("conversations/messages", form);
        state.msgText = "";
        state.finalBlob = null;
        state.previewUrl = "";
        state.files = [];
        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        state.isSending = false;
      }
    }

    onUnmounted(() => {
      if (audioStream)
        audioStream.getTracks().forEach(function (track) {
          track.stop();
        });
    });

    return {
      state,
      //uploadSizeLimit,

      sendMsg,
      canSend,

      filePicker,
      hideFileDetails,
      attachmentsValidation,
      openFilePicker,

      recordingPreview,
      toggleRecording,
      clearRecording,

      icons: {
        mdiSend,
        mdiDelete,
        mdiMicrophone,
        mdiStop,
        mdiFile,
      },
    };
  },
};
</script>

<style></style> -->


<template>
  <v-card-text class="d-flex flex-column">
    <form @submit.prevent="sendMsg" class="d-flex flex-row align-center pt-5">

      <div class="inbox-footer">

        <v-textarea class="message-text-field" :no-resize="true" rows="3"
          :disabled="state.isSending || attachmentLoading" v-model="state.msgText" hide-details="" rounded dense
          placeholder="Type Here" clear-icon>
        </v-textarea>

        <div>
          <div class="d-flex flex-row" v-if="props.recording">

            <v-btn v-show="!state.previewUrl" @click="toggleRecording" color="blue" rounded small text>
              <v-icon :color="state.isRecording ? '#F60000' : ''" size="20">
                {{ state.isRecording ? icons.mdiStop : icons.mdiMicrophone }}
              </v-icon>
              {{ state.isRecording ? '' : 'Record' }}
              {{ state.isRecording ? time : "" }}
            </v-btn>

            <audio v-show="state.previewUrl" ref="recordingPreview" class="audio-hidden" controls src=""></audio>

            <v-btn v-show="state.previewUrl" text rounded small color="blue" @click="clearRecording">
              <v-icon v-show="state.previewUrl" @click="clearRecording" size="20" color="primary">{{
                icons.mdiDeleteEmpty
              }}</v-icon>
              Delete
            </v-btn>

          </div>

          <div class="inner-attach-send">
            <div class="mt-0 d-flex align-center" v-if="props.attachment">
              <v-btn color="blue" fab small text :loading="attachmentLoading" @click="openFilePicker">
                <v-icon size="20">{{ icons.mdiPaperclip }}</v-icon>
              </v-btn>

              <v-file-input v-model="state.files" hide-input hidden @change="sendAttachment"
                :rules="attachmentsValidation" ref="filePicker" dense outlined label="Add attachments to message"
                class="d-none" clearable small-chips autofocus show-size="" multiple>
              </v-file-input>
            </div>
            <v-btn @click="sendMsg" :loading="state.isSending" color="blue" fab small text class="send-msg-btn mr-1">
              <v-icon size="20">{{ icons.mdiSend }}</v-icon>
            </v-btn>
          </div>
        </div>

      </div>

    </form>

  </v-card-text>
</template>

<script>
import { useMessages } from "@/composables/chat/messages";
import { validateFileSizes } from "@/helpers";
import axios from "@axios";
import { mdiDeleteEmpty, mdiFile, mdiMicrophone, mdiSend, mdiStop, mdiPaperclip } from "@mdi/js";
import { computed, onUnmounted, reactive, ref } from "@vue/composition-api";


export default {
  name: "SendMessage",
  props: {
    recording: {
      type: Boolean,
      default: true,
      required: true
    },
    attachment: {
      type: Boolean,
      default: true,
      required: true
    }

  },
  setup(props) {

    const { activeConversation } = useMessages();

    const state = reactive({
      sizeError: false,
      msgText: "",
      previewUrl: "",
      isSending: false,
      isRecording: false,
      finalBlob: null,
      files: [],
    });


    const recordingPreview = ref(null);
    const attachmentLoading = ref(false)
    const filePicker = ref(null);
    const uploadSizeLimit = 50;
    const attachmentsValidation = [(files) => validateFileSizes(files, uploadSizeLimit)];

    function openFilePicker() {
      const pickerElem = filePicker.value.$el;
      const button = pickerElem.querySelector("button");
      button.click();
    }

    function openAlert() {
      alert('HellO Input')
    }

    const canSend = computed(() => {
      if (state.isRecording) return false;

      //const filesAboveLimit = state.files.some((file) => file.size > uploadSizeLimit * 1000 * 1000);
      //if (filesAboveLimit) return false;

      if (state.msgText || state.files.length || state.finalBlob) return true;

      return false;
    });

    var audioStream = null,
      mediaRecorder = null,
      audioChunks = [];

    let interval = null;
    const time = ref('00:00')

    const pad = (val) => {
      let valueString = val + ''
      if (valueString.length < 2) {
        return `0${valueString}`
      } else {
        return valueString
      }
    }

    const timer = () => {
      let totalSeconds = 0
      if (interval === null) {
        interval = setInterval(() => {
          ++totalSeconds
          let s = pad(totalSeconds % 60)
          let m = pad(parseInt(totalSeconds / 60))
          time.value = `${m}:${s}`
        }, 1000)
      } else {
        clearInterval(interval)
        interval = null
        time.value = '00:00'
        console.log('Clear Interval')
      }
    }

    async function startRecording() {
      try {
        audioChunks = [];
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            sampleRate: 44100,
          },
        });
        mediaRecorder = new MediaRecorder(audioStream);
        mediaRecorder.ondataavailable = function (event) {
          audioChunks.push(event.data);
        };
        mediaRecorder.onstop = handleStop;
        mediaRecorder.start();
        state.isRecording = true;
        timer()

      } catch (err) {
        console.log(err);
      }
    }

    function stopRecording() {
      mediaRecorder.stop();
      timer()
    }

    function handleStop() {
      audioStream.getTracks().forEach(function (track) {
        track.stop();
      });
      state.finalBlob = new Blob(audioChunks, { type: "audio/webm" });
      state.previewUrl = URL.createObjectURL(state.finalBlob);
      recordingPreview.value.src = state.previewUrl;
    }

    function toggleRecording() {
      if (state.isRecording) stopRecording();
      else startRecording();
      state.isRecording = !state.isRecording;
    }

    function clearRecording() {
      state.previewUrl = "";
      audioChunks = [];
      state.finalBlob = null;
    }

    const hideFileDetails = computed(() => {
      if (!state.files.length) return true;
      return false;
    });

    async function uploadAttachment() {
      try {
        attachmentLoading.value = true
        await sendMsg()
      } catch (err) {
        console.log('Error : ', err)
        throw err
      }
      finally {
        attachmentLoading.value = false
      }

    }

    async function sendMsg() {
      console.log("conversationId SEND MESSAGE", activeConversation.value.id)

      try {
        if (!canSend.value) return;
        state.isSending = true;
        const form = new FormData();
        form.append("conversationId", activeConversation.value.id);
        form.append("message", state.msgText);
        // for (var file of state.files) form.append("attachments", file);
        if (state.finalBlob) form.append("attachments", state.finalBlob, `${Date.now()}.mp3`);
        const response = await axios.post("conversations/messages", form);

        // addMessage(response.data)
        state.msgText = "";
        state.finalBlob = null;
        state.previewUrl = "";
        // state.files = [];
        console.log(response.data);

      } catch (err) {
        console.log(err);
      } finally {
        state.isSending = false;
      }
    }

    async function sendAttachment() {
      try {
        // if (!canSend.value) return;
        attachmentLoading.value = true;
        const form = new FormData();
        form.append("conversationId", activeConversation.value.id);
        form.append("message", '');
        for (var file of state.files) form.append("attachments", file);
        const response = await axios.post("conversations/messages", form);
        state.files = [];
        console.log(response.data);
      }
      catch (err) {
        console.log(err);
      } finally {
        attachmentLoading.value = false;
      }
    }

    onUnmounted(() => {
      if (audioStream)
        audioStream.getTracks().forEach(function (track) {
          track.stop();
        });
    });

    return {
      state,
      //uploadSizeLimit,
      time,
      sendMsg,
      canSend,

      filePicker,
      hideFileDetails,
      attachmentsValidation,
      openFilePicker,

      recordingPreview,
      toggleRecording,
      clearRecording,
      props,
      openAlert,
      uploadAttachment,
      attachmentLoading,
      sendAttachment,
      icons: {
        mdiSend,
        mdiDeleteEmpty,
        mdiMicrophone,
        mdiStop,
        mdiFile,
        mdiPaperclip,
      },
    };
  },
};
</script>

<style>

.inner-attach-send{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.audio-hidden {
  display: none;
}

.hide-file-upload{
  display: none !important;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  gap: 25px;
}

.box>div {
  margin-top: 15px;
}

.box>div>button {
  width: 100% !important;
  margin-top: 15px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.inbox-footer{
  position: relative;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content:flex-end ;
  align-items: flex-end;
  border-radius: 15px;
  border: 2px solid rgba(141, 148, 158, 0.15)
}
.v_ripple_container{
  background: transparent !important;
}
/* .width-none{
  max-width: 0px;
  padding: 0px !important;
} */

.message-text-field{
  margin-top: 0px;
  padding: 0px !important;
  overflow: hidden !important;
}

@media (max-width: 767px) {
  .chat-att {
    flex-direction: row !important;
    align-items: center;
  }

  .chat-att button {
    margin-top: 5px !important;
    padding: 20px 10px !important;
  }

  .chat-buttons>div {
    width: 100%;
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .box>div {
    width: 100% !important;
  }

  .box>div>button {
    width: 100% !important;
    margin-top: 15px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-file-input.d-flex {
    margin-top: 25px;
  }
}
</style>
